/** 融资服务 */
<template>
  <div class="product">
    <el-image class="IPBS-banner" v-if="$isPc" :src="require('@/assets/images/product-banner1.png')"></el-image>
    <section v-if="!$isPc" class="select-con  justify-between align-center" :class="[$isPc?'ptb-30':'ptb-20']">
      <Search @searchBtn="searchBtn" />
    </section>
    <template v-if="$isPc">
      <!-- 产品推荐 -->
      <section class="product-recommend content">
        <div class="ipbs-title">
          <h4>产品推荐</h4>
        </div>
        <el-row :gutter="20" class="recommend-list">
          <el-col v-if="Object.keys(productHot).length" :span="12">
            <router-link :to="{ path: '/product_details', query: { id: productHot.id } }">
              <div class="recommend-hot" :style="{backgroundColor:productHot.backColor}">
                <div class="recommend-hot-desc justify-between mb-30">
                  <div class="flex-column justify-between">
                    <div class="product-img flex-center">
                      <el-image :src="$base+productHot.product_logo" fit="contain"></el-image>
                    </div>
                    <p class="product-name mtb-20"><strong>{{productHot.product_name}}</strong></p>
                  </div>
                  <el-image :src="require('@/assets/images/product-hot.png')" fit="contain"> </el-image>
                </div>
                <p class="recommend-hot-info text-ellipsis-lines" v-html="productHot.product_introduct"></p>
              </div>
            </router-link>
          </el-col>
          <el-col :span="6" v-for="(item,index) in productData" :key="index">
            <div class="recommend-item" :style="{backgroundColor:item.backColor}">
              <div class="product-img flex-center">
                <el-image :src="$base+item.product_logo" fit="contain"></el-image>
              </div>
              <p class="product-name mtb-20"><strong>{{item.product_name}}</strong></p>
              <router-link :to="{ path: '/product_details', query: { id: item.id } }">
                <el-button type="primary">了解详情 </el-button>
              </router-link>
            </div>
          </el-col>
        </el-row>
      </section>
      <!-- 融资条件 -->
      <section class="product-conditions content">
        <div class="ipbs-title">
          <h4>融资条件</h4>
        </div>
        <el-row type="felx" justify="space-around" class="conditions-list">
          <el-col :span="8" class="conditions-item" v-for="(item,index) in conditions" :key="index">
            <el-image class="conditions-img" :src="item.img"></el-image>
            <h4 class="conditions-title mtb-30">{{item.title}}</h4>
            <p class="conditions-info">{{item.info}}</p>
          </el-col>
        </el-row>
      </section>
      <!-- 融资流程 -->
      <section class="product-process content">
        <div class="ipbs-title">
          <h4>融资流程</h4>
        </div>
        <ul class="process-list flex">
          <li class="process-item align-center" v-for="(item,index) in process" :key="index">
            <div class="process-desc">
              <!-- <h4 class="process-title">{{item.title}}</h4> -->
              <div class="process-info align-center">
                <p class="process-info-text">{{item.info}}</p>
              </div>

            </div>
            <i v-if="index<process.length-1" class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </section>
    </template>
    <LoadMore v-if="!$isPc" :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
      <List :data="productData" :loading="loading" :total="pageTotal" :pageSize="20" :resetPage="resetPage"
        @page="changePage">
        <template v-slot="{item}">
          <router-link :to="{ path: '/product_details', query: { id: item.id } }">
            <div :class="['product-info', 'flex-column', $isPc?'justify-between':'justify-around']">
              <div :class="[$isPc?'ptb-30':'pa-10']">
                <div :class="['product-img', 'flex-center',$isPc?'mb-30':'mb-10' ]">
                  <el-image :src="$base+item.product_logo" fit="contain"></el-image>
                </div>
                <p class="product-name flex-center"><strong>{{item.product_name}}</strong></p>
              </div>
              <div class="product-btn justify-around align-center">
                <el-button type="primary" plain>了解详情 </el-button>
              </div>
            </div>
          </router-link>
        </template>
      </List>
    </LoadMore>
  </div>
</template>
<script>
import { getProduct } from '@/api/product'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
import Search from '@/components/Search.vue'
export default {
  name: 'Product',
  components: {
    LoadMore,
    List,
    Search,
  },
  data () {
    return {
      id: '',
      filtersData: {
        keywords: '',
        limit: 20,
        page: 1,
      },
      // 融资条件
      conditions: [
        { img: require('@/assets/images/product-img1.png'), title: '信用状况良好', info: '企业连续经营两年以上且企业及实际控制人信用状况良好；' },
        { img: require('@/assets/images/product-img2.png'), title: '拥有知识产权', info: '核心知识产权为发明专利、实用新型专利、驰名商标、著名商标、软件著作权；' },
        { img: require('@/assets/images/product-img3.png'), title: '财务状况良好', info: '年营业额原则上不低于1000万元，且利润为正；' },
      ],
      process: [
        { title: '提交资料', info: '企业向平台提出申请，了解融资产品情况' },
        { title: '银行审核', info: '企业填写调查表，平台审核' },
        { title: '项目评估', info: '银行等金融机构尽调' },
        { title: '签约合同', info: '资产评估、质权登记' },
        { title: '质权登记', info: '金融机构放款' },
        { title: '成功融资', info: '按时还款，申请补贴' },
      ],
      pageTotal: 0,
      productHot: {},
      productData: [],
      backColors: ['#8495CE', '#B0A4DE', '#6BB1E0', '#A6B4E0'],
      loading: false,//正在加载
      finished: false, // 加载完成
      isReset: false,//是否重置数据
      resetPage: false,//分页重置
    }
  },
  mounted () {
    this.onLoadMore()
  },
  methods: {
    async getProduct (filtersData) {
      try {
        let { data, total } = await getProduct(filtersData)
        this.productHot = this.$isPc ? data.splice(0, 1)[0] : {}
        this.productHot['backColor'] = '#D5DFFF'
        this.productData = this.$isPc ? data : [...this.productData, ...data]
        this.productData.forEach((v, i) => {
          v['backColor'] = this.backColors[i % 4]
        })
        this.pageTotal = total
        this.isReset = false
        this.loading = false;
      } catch (error) { }
    },
    // 搜索
    searchBtn (val) {
      this.filtersData.keywords = val
      this.isReset = true
      this.onLoadMore()
    },
    // 整合查询数据
    async setFiltersData () {
      if (this.isReset) {
        this.pageTotal = 0
        this.productData = []
        this.filtersData.page = 1
        this.resetPage = !this.resetPage
        this.loading = true
        this.finished = false
      }

      if (!this.filtersData.keywords) {
        delete this.filtersData.keywords
      }
      let newFiltersData = JSON.parse(JSON.stringify(this.filtersData));

      await this.getProduct(newFiltersData)
    },
    // 切换分页
    changePage (page) {
      this.filtersData.page = page
      this.onLoadMore()
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.setFiltersData()
      done && done();
      if (this.productData.length >= this.pageTotal - 1) {
        this.finished = true
        return
      }
      this.filtersData.page += 1
    },
  },
}
</script>
<style lang="scss" scoped>
// 产品推荐
.product-recommend {
  .recommend-list {
    .el-col {
      cursor: pointer;
      margin-bottom: 20px;
      & > div {
        border-radius: 5px;
      }
    }
    .recommend-hot {
      height: 290px;
      padding: 50px;
      .product-name {
        font-size: 36px;
        color: #474c5c;
      }
      .recommend-hot-info {
        line-height: 32px;
        color: #474c5c;
      }
    }
    .recommend-item {
      height: 145px;
      padding: 20px;
      text-align: center;
      .product-img {
        height: 50px;
        .el-image {
          height: 100%;
        }
      }
      .product-name {
        text-align: center;
        color: #fff;
      }
      .el-button {
        padding: 6px 16px;
      }
    }
  }
}
// 基本条件
.product-conditions {
  .conditions-list {
    .conditions-item {
      padding: 50px;
      text-align: center;
      .conditions-img {
        max-width: 214px;
      }
      .conditions-title {
        font-size: 30px;
        color: #474c5c;
      }
      .conditions-info {
        line-height: 24px;
        color: #191d2a;
      }
    }
  }
}
// 服务流程
.product-process {
  .process-list {
    .process-item {
      width: 16%;
      position: relative;
      margin-right: 35px;
      &:last-child {
        margin-right: 0;
      }
      .process-desc {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        .process-title {
          padding: 20px 0;
          color: #ffffff;
          background-color: #1997ef;
        }
        .process-info {
          padding: 0 20px;
          height: 130px;
          background-color: #e5f2ff;
          .process-info-text {
            line-height: 24px;
            color: #090b09;
            text-align: left;
          }
        }
      }
      i {
        position: absolute;
        right: -30px;
        display: block;
        color: #fff;
        background-color: #a0c2da;
        padding: 5px;
        border-radius: 50%;
      }
    }
  }
}

/deep/.list {
  flex-direction: row;
  .item {
    padding: 0;
    width: 24%;
    height: auto;
    border-bottom: none;
    margin-bottom: 15px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  /deep/ .search {
    padding: 0 10px;
  }
  /deep/.list {
    .item {
      width: 48%;
    }
  }
}
</style>